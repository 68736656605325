@import '~swiper/swiper.min.css';
@import '~swiper/swiper-bundle.min.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
html {
  font-size: 18px;
  overflow-x: hidden;
}
@media (max-width: 1920px) {
  html {
    font-size: 16px;
  }
}
@media (max-width: 1600px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 1500px) {
  html {
    font-size: 12px;
  }
}
@media (max-width: 1200px) {
  html {
    font-size: 10px;
  }
}
@media (max-width: 992px) {
  html {
    font-size: 8px;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 6px;
  }
}
@media (max-width: 576px) {
  html {
    font-size: 18px;
  }
}

* {
  font-family: "Muli" !important;
  outline: none;
  text-decoration: none;
  margin: 0;
  border: none;
  letter-spacing: normal;
  box-sizing: border-box;
}

ul,
ol,
li {
  list-style: none;
}

.article-content .body ul,
.article-content .body ul li {
  list-style: disc;
}

.article-content .body ol,
.article-content .body ol li {
  list-style: decimal;
}

.article-content .body img {
  max-width: 100%;
}

.article-content .body p {
  word-break: break-word;
}

body {
  overflow: hidden;
}

.container {
  margin: 0 auto;
  width: 78%;
  max-width: 1920px;
}

.container-left-offset {
  margin-left: 11%;
  margin-right: auto;
  width: 89%;
  max-width: 1920px;
}

.container-right-offset {
  margin-right: 11%;
  margin-left: auto;
  width: 89%;
  max-width: 1920px;
}

.big-title {
  font: 700 3.5rem "Muli";
  color: #303e5b;
}

.title {
  font: 700 2rem "Muli";
  color: #303e5b;
}

.sub-title {
  font: 700 1.5rem "Muli";
  color: #303e5b;
}

.small-sub-title {
  font: 400 1.2rem "Muli";
  color: #303e5b;
}

.smaller-sub-title {
  font: 400 1.1rem "Muli";
  color: #303e5b;
}

.paragraph {
  font: 700 1.4rem "Muli";
  color: #303e5b;
}

.paragraph2 {
  font: 400 1rem "Muli";
  color: #303e5b;
}

.primary-btn {
  padding: 0.625rem 3.8rem;
  font: 600 1rem "Muli";
  color: #ffffff;
  cursor: pointer;
  background: linear-gradient(84.61deg, #5ececa -7.34%, #50aff2 44.99%, #6887f7 106.67%);
  border-radius: 1.5rem;
}
.primary-btn:disabled {
  background: #E6EBF7 !important;
  color: #A8B4D1 !important;
  border-color: #E6EBF7 !important;
  cursor: not-allowed;
}

.primary-btn-transparent {
  border: 1px solid #ffffff;
  padding: 0.7rem 3.8rem;
  font: 600 1rem "Muli";
  color: #ffffff;
  cursor: pointer;
  background: transparent;
  border-radius: 1.5rem;
  transition: 0.3s all;
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.cdk-overlay-dark-backdrop {
  backdrop-filter: blur(4px);
}

.secondary-btn {
  background: #e6ebf7;
  padding: 0.7rem 3.8rem;
  font: 600 1rem "Muli";
  color: #a8b4d1;
  cursor: pointer;
  border-radius: 1.5rem;
}

.material-icons {
  font-family: "Material Icons";
  position: relative;
  top: 0.27rem;
}

.data-input {
  width: 100%;
  padding: 0.7rem 0.8rem;
  background: #ffffff;
  border: 1px solid #f0f5fd;
  box-shadow: 0 0.3rem 3.5rem rgba(0, 0, 0, 0.07);
  border-radius: 0.2rem;
  color: #000000;
}

select.default-select {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.text-field {
  display: flex;
}
.text-field p {
  margin-left: 20px;
}
.text-field p span {
  color: #60d2c7;
}
.text-field input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  margin: 0;
  height: 1.2rem;
  width: 1.2rem;
  border: 1px solid #7673f6;
  box-sizing: border-box;
  border-radius: 0.3rem;
  outline: none;
  padding: 0.4rem;
  cursor: pointer;
}
.text-field input:checked {
  border: none;
  height: 1.2rem;
  width: 1.2rem;
  background: linear-gradient(88.92deg, #5ececa -7.34%, #50aff2 44.99%, #6887f7 106.67%);
  border-radius: 0.3rem;
}
.text-field input:checked::before {
  border: 0.11rem solid #ffffff;
  border-top: none;
  border-right: none;
  content: "";
  height: 0.33rem;
  left: 0.2rem;
  position: absolute;
  top: 0.2rem;
  transform: rotate(-45deg);
  width: 0.6rem;
  background: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::ng-deep.mat-snack-bar-container {
  min-width: 12rem !important;
  background: #303e5b;
  position: absolute;
  color: #f0f5fd;
  bottom: 0;
  right: 45%;
}
::ng-deep.mat-snack-bar-container .mat-simple-snackbar-action button {
  display: none;
}
::ng-deep.mat-snack-bar-container .mat-simple-snackbar {
  justify-content: center;
}

.select-arrow {
  margin: 0 -1.6rem;
}

.code-country {
  z-index: 99;
  line-height: 25px;
  font-size: 0.9rem;
  padding: 2px;
  margin-left: 10px;
  position: absolute;
  font-weight: bold;
  top: 39%;
}

.move-right.phone-number-code {
  text-indent: 40px;
}

.move-right-long.phone-number-code {
  text-indent: 50px;
}

.max-words {
  color: #6a7794;
  font-size: 0.9rem;
  position: absolute;
  right: 20%;
  bottom: 2rem;
}
.max-words .danger {
  color: #FF4B55;
}

textarea {
  resize: none !important;
}

::ng-deep input.star-5:checked ~ label.star:before {
  text-shadow: none !important;
}

/* Ratings widget */
.rate {
  display: inline-block;
  border: 0;
}

/* Hide radio */
.rate > input {
  display: none;
}

/* Order correctly by floating highest to the right */
.rate > label {
  float: right;
}

/* The star of the show */
.rate > label:before {
  display: inline-block;
  font-size: 18.2px;
  padding: 0.3rem 0.2rem;
  margin: 0;
  cursor: pointer;
  font-family: FontAwesome;
  content: "\f005"; /* full star */
}

/* Zero stars rating */
.rate > label:last-child:before {
  content: "\f006"; /* empty star outline */
}

/* Half star trick */
.rate .half:before {
  content: "\f089"; /* half star no outline */
  position: absolute;
  padding-right: 0;
}

/* Click + hover color */
.rate input ~ label {
  color: #dff6f4;
}
.rate input:checked ~ label, .rate input.isChecked ~ label,
.rate label:hover, .rate label:hover ~ label {
  color: #60d2c7;
}

::ng-deep mat-option.mat-option {
  padding: 0 20px;
}
::ng-deep mat-option.mat-option .mat-option-text {
  margin: 0 20px;
}

/* color previous stars on hover */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 8px;
}

form.stripe {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  /* Buttons and links */
}
form.stripe .hidden {
  display: none;
}
form.stripe #payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}
form.stripe #payment-element {
  margin-bottom: 24px;
}
form.stripe button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  margin-top: 40px;
}
form.stripe button:hover {
  filter: contrast(115%);
}
form.stripe button:disabled {
  opacity: 0.5;
  cursor: default;
}

.mat-icon {
  color: #60d2c7 !important;
  font-size: 20px !important;
  cursor: pointer;
}

.mat-tooltip {
  font-size: 1rem !important;
}

.mat-select-placeholder,
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #a8b4d1;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  display: none;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 1rem !important;
  padding: 24px;
}

.mat-mdc-menu-panel.mdc-menu-surface {
  border-radius: 17.3793px;
  margin-top: 10px;
}
.mat-mdc-menu-panel.mdc-menu-surface .mat-mdc-menu-item {
  padding: 0 24px;
}

.mdc-switch__icons {
  display: none !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: linear-gradient(88.89deg, #5ececa -7.34%, #50aff2 44.99%, #6887f7 106.67%) !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  opacity: 0.3;
}

.mat-mdc-form-field {
  background: #ffffff;
  border: 1px solid #eeeeef;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  width: 80%;
  margin-top: 12px;
  margin-bottom: 30px;
}

.mat-mdc-form-field-infix {
  min-height: unset !important;
  padding: 1rem 7px 1rem 7px !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-text-field-wrapper {
  padding: 0 !important;
  background-color: transparent !important;
  line-height: 0 !important;
}

.mat-mdc-select-arrow-wrapper {
  height: 18px !important;
}

.mat-mdc-select-value,
.mat-mdc-select {
  font-size: 13.3px;
  line-height: 1;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border: none;
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-mdc-menu-item-highlighted:not([disabled]) {
  background: #dff6f4 !important;
}

.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  font: 400 1rem Muli;
  color: #303e5b;
}

.material-icons {
  font-family: "Material Icons" !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background: #fff !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
  background-color: rgba(0, 0, 0, 0.38) !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
}

.mat-horizontal-stepper-wrapper,
.mat-horizontal-content-container,
.mat-horizontal-stepper-content {
  height: 100%;
}